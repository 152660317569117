'use client';
import ReactModal, { Props } from 'react-modal';

const Modal = (props: Props) => {
  const appRoot = document.getElementById('appRoot');

  return appRoot ? <ReactModal {...props} appElement={appRoot} /> : null;
};

export { Modal };
